import {
  Avatar,
  AvatarIcon,
  Review,
  ReviewSection,
  StarIconsDiv,
  TestimonialMain,
} from "../../styles/testimonial";
import * as Icon from "react-feather";
import { SupplText } from "../../styles/styles";

const Testimonial = () => {
  return (
    <>
      
    </>
  );
};

export default Testimonial;

const data = [
 
  {
    review: "The sixth extension for Visual Studio Code is a valuable tool for developers looking to enhance their coding efficiency and collaboration. With its intelligent code suggestions, real-time error detection, and customizable features, it offers a significant boost to productivity. However, users should be mindful of its resource demands and occasional inaccuracies. Overall, it's a solid addition to any developer's toolkit ",
    user: "Tunde, CEO of Bridgecard",
    stars: 5,
    image: "/images/Tunde Bridgecard.jpeg",
  },
  {
    review: " The AI Assistant for Visual Studio Code is a handy extension that boosts coding efficiency with smart code suggestions and real-time error detection. It also supports seamless collaboration with team members. While it can be resource-intensive and has a slight learning curve, its benefits make it a valuable tool for developers. ",
    user: "Abdul, CEO of Mono ",
    stars: 2,
    image: "/images/Abdul Mono.jpeg",
  },
];
