import styled from "styled-components";

export const FaqMainDiv = styled.div``;
export const FaqHeader = styled.div`
  display: flex;
  gap: 8rem;
  justify-content: right;
  margin: 1% 4rem;
  > h1 {
    font-size: 40px;
    margin: 0px 20%;
  }
  @media (max-width: 650px) {
    margin: 5% 1rem;
    gap: 1rem;
    h1 {
      margin: 0px;
    }
  }
  @media (max-width: 450px) {
    flex-wrap: wrap;
    > h1 {
      font-size: 24px;
      text-align: center;
    }
    margin: 5% 0rem;
    gap: 3rem;
    justify-content: center;
  }
`;
export const FaqMainSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  margin: 4rem 4%;
  flex-grow: 1;
  gap: 4%;
`;
export const FaqSection = styled.div`
  width: 48%;

  @media (max-width: 450px) {
    width: 100%;
    p {
      text-align: center;
    }
  }
`;

export const FaqQuestionDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  > h3 {
    width: 80%;
  }
`;
export const FaqFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    margin: 0px;
  }
  span {
    color: var(--sixthPurple);
    cursor: pointer;
    :hover {
      color: var(--sixthLightPurple);
    }
  }
`;

export const FaqFieldMain = styled.div`
  width: 100%;
  background: var(--sixth-gradient5);
  padding: 5rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    margin-left: 8rem;
  }
  @media (max-width: 450px) {
    h1 {
      padding: auto;
    }
  }
`;

export const FaqFieldHead = styled.div `
display:flex;
justify-content:space-between;
align-items:center;
@media (max-width:450px) {
  margin:auto 1rem;
}

`

export const FaqContent = styled.div `

@media (max-width:450px) {
  margin:auto 1rem;
}


`
interface FaqDropDownProp 
{
  open?:boolean
}
export const FaqDropDown = styled.img<FaqDropDownProp>`
cursor:pointer;
 transition: 1s ;
transform:rotate( ${(p) => p.open === true ? "180deg":"0"})  ;


`
