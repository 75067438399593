import styled from "styled-components";

export const FooterMain = styled.section`
    margin: 0 0 0 0;
    padding:2rem 5% 0 5%;
    background: rgba(140, 48, 245, 1);
    position:relative;
    color:white;

    @media (max-width:900px){
        padding: 2rem 10% 0 10%;
    }

    @media (min-width:700px){
        padding: 4rem 10% 0 10%;
    }

    @media (min-width:1100px){
        padding: 2rem 5% 0 5%;
    }
`

interface FlexProps {
    align?: any;
}

export const FooterFlex = styled.div<FlexProps>`
    display:flex;
    align-items:${p => p.align || 'flex-start'};
    justify-content:space-between;

    @media (max-width:900px){
        flex-direction:column;
    }
`

export const FooterAbout = styled.div`
    width:25%;
    border-radius:10px;
    padding:3rem 1.6rem;
    color: #474747;

    > h3 {
        font-weight: 500;
        font-size: 30px;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height:24px;
        letter-spacing: 0.005em;
    }

    @media (max-width:900px){
        width:90%;
        padding:1rem 0%;
    }
`

export const FooterSocialWrap = styled.div`
    display:Flex;
    align-items:center;
    justify-content:space-between;
    padding: 1rem 5%;
    gap:10px;

    > div {
        display:flex;
        align-items:Center;
        justify-content:center;
        width:2rem;
        height:2rem;
        border-radius:10rem;
        background: #8C30F5;
        color: #fff;
    }
`

export const FooterNavigations = styled.div`
    width:60%;
    display:flex;
    color: rgba(0, 0, 0, 0.87);;
    justify-content:space-between;
    margin:2rem 0 0 0;

    > div {

        width:25%;

        > h3 {
            font-weight: 700;
            font-size: 15px;
        }

        p {
            font-weight: 400;
            font-size: 13px;
            line-height:24px;
            cursor:pointer;

            :hover {
                opacity: 0.8;
            }
        }

        > div {
            display:Flex;
            align-items:center;
            justify-content:flex-start;
            gap:20px;
        }

        > div > img {
            width:7rem;
        }

        > div > img:first-child{
            position:relative;
            top:0.3rem;
        }
    }

    > div:nth-child(3){
        width:40%;
    }

    @media (max-width:900px){
        width:100%;
        flex-wrap:wrap;

        > div {
            width:45%;

            > div {
                justify-content:flex-start;
                gap:40px;
            }
            
        }

        > div:nth-child(3) {
            width:100%;
            order:0;
        }

        > div:nth-child(1) {
            order:1;
            margin:3rem 0 0 0;
        }

        > div:nth-child(2) {
            order:2;
            margin:3rem 0 0 0;
        }
    }
`

export const CopyRightText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: color;
`

export const RoundedSheet = styled.div`
    position:relative;
    bottom:0;
    left:50%;
    transform: translate(-50%, 0);
    height:0.5rem;
    border-radius:10px 10px 0 0;
    background: var(--main-bg);
`