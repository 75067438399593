import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const RedirectPage = () => {
  const firebaseConfig = {
    
      apiKey: "AIzaSyDpqU2toL-ZNzAYN7fflqqtYamWXp22Lm0",
      authDomain: "sixth-v2.firebaseapp.com",
      databaseURL: "https://sixth-v2-default-rtdb.firebaseio.com",
      projectId: "sixth-v2",
      storageBucket: "sixth-v2.appspot.com",
      messagingSenderId: "971026243533",
      appId: "1:971026243533:web:a047ecc7d6e60a8a42ee84",
      measurementId: "G-2W6SFLPJ7B"
    
  };

  const firebaseAPP = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseAPP);

  const redirect = () => {
    var address = window.location.href;
    var source = address.split("https://trysixth.com/").pop() + "";

    logEvent(analytics, source);
    console.log(source);
    setTimeout(function () {
      window.location.href = "https://trysixth.com";
    }, 2000);
  };

  return (
    <>
      <div></div>
      {redirect()}
    </>
  );
};

export default RedirectPage;
