import React from "react";
import {
  ServiceMain,
  
} from "../../styles/services";
import {
  StepDiv,
  StepHero,
  StepHeroImgDiv,
  StepMainDiv,
} from "../../styles/homeComp/toolstyles";
import { Fade } from "react-awesome-reveal";

const Tools = () => {
 
  return (
    <>
      <ServiceMain>
        <Fade duration={3000}>
          <StepMainDiv>
            <h3 style={{ lineHeight: "1em" }}>
              Enhancing code security. 
            </h3>
            <StepHero>
              {data.map((item, index) => (
                <StepDiv>
                  
                    <h1>{item.title}</h1>
                    <p>{item.text}</p>
                  
                </StepDiv>
              ))}
            </StepHero>
          </StepMainDiv>
        </Fade>
      </ServiceMain>
    </>
  );
};

export default Tools;

const data = [
  {
    title: "Developer Driven",
    text: "Install, authenticate, and let our AI take over instantly for a hassle free experience.",
    availText: "Coming Soon",
    ready: false,
    image: "/images/teenyicons_code-solid.png",
    bg: "/images/teenyicons_code-solid.png",
  },

  {
    title: "Secure fixes",
    text: "Receive reliable security fixes that replace insecure implementations.",
    availText: "Coming soon",
    ready: false,
    image: "/images/fluent-mdl2_laptop-secure.png",
    bg: "/images/fluent-mdl2_laptop-secure.png",
  },
  {
    title: "Lightening fast Detection",
    text: "Detect vulnerabilities in real time as you code - no lag or delays.",
    availText: "Coming Soon",
    ready: false,
    image: "/images/twemoji_woman-detective.png",
    bg: "/images/twemoji_woman-detective.png",
  },
];
