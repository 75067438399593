import React from "react";
import {
  FlexedData,
  ServiceMain,
  CardSection,
} from "../../styles/services";
import {Zoom, Slide, } from "react-awesome-reveal";
import { LeftHero, RightHero, SupplText, TextHeader } from "../../styles/styles";
import { VsHeroMain } from "../../styles/products";

const Services = () => {
  return (
    <>
      <ServiceMain>
        <Slide>
          <CardSection margin="0">
            <FlexedData
              bg="var(--sixth-gradient1)"
              radius="50px"
              width="100%"
             
            >
              <section>
                <h2>
                  Detect and Fix <br />
                  Vulnerabilities <br />
                  Instantly with our AI- <br />
                  Powered VS Code <br />
                  Extension!
                </h2>
                <p>
                  Get access to crucial security loopholes and fixes <br />
                  effortlessly in your code, keeping your workflow <br />
                  smooth and uninterrupted.
                </p>
              </section>
              <img src="/images/apiHero.png" alt="Card" />
            </FlexedData>
          </CardSection>
        </Slide>
        <Slide>
          <CardSection margin="0">
            <FlexedData
              bg="var(--sixth-gradient1)"
              radius="50px"
              width="100%"
              
            >
              <img src="/images/vulnerabilityHeroIcon.png" alt="hacker" />

              <section>
                <h2>
                  Instantly spot diverse
                  <br />
                  vulnerabilities in real <br />
                  time.
                </h2>
                <SupplText>
                  Get real time detection of vulnerabilities from over 10,000{" "}
                  <br />
                  known vulnerabilities, including OWASP, CWE and the CVE <br />
                  database.
                </SupplText>
              </section>
            </FlexedData>
          </CardSection>
        </Slide>
      </ServiceMain>

    </>
  );
};

export default Services;
