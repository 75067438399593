import styled from "styled-components";

export const ServiceMain = styled.section`
    margin: 5rem auto;
    padding: 0;
    text-align:center;
    > h2 {
      
        font-weight: 800;
        font-size: 55px;
        line-height: 70px;
        color: red;
        margin:0 auto;
        
    }
  
    > p {
        padding:0 17%;
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;
    }
   
    @media (max-width:600px){
        margin: 0rem auto 0 auto;
        padding: 2rem 0%;

        > h2 {
            font-size:25px;
            line-height:35px;
            width:90%;
        }

        > p {
            padding:0 5%;
        }
    }
`
interface FlexDataProp {
  bg?:string;
  sectionWidth?:string;
  radius?:string;
  width?:string;
  height?:string;

}
export const FlexedData = styled.div<FlexDataProp>`
  display: flex;
  justify-content: space-between;
  padding: 0 0%;
  border-radius: ${(p) => p.radius || "0px"};
  width: ${(p) => p.width || "auto"};
  min-height: ${(p) => p.height || "auto"};
  min-width: 360px;
  max-width: 1300px;
  margin: 1rem 0;

  > section {
    width: ${(p) => p.sectionWidth || "35%"};
    min-height: 500px;
    text-align: left;
    background: red;
    padding: 0 50px;
    border-radius: 28px;
    background: #010409;
    background-size: 100% 100%;
    background-position: 0px 0px, 0px 0px;
    background-size: 100% 100%;
    background-position: 0px 0px, 0px 0px;
    background: linear-gradient(180deg, #000000 0%, #10062b 100%);

    h1,
    h2,
    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 2.5rem;
      line-height: 1.2em;
    }

    h3 {
      font-size: 15px;
      line-height: 20px;
    }

    p {
      font-weight: 450;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0px;
    }
  }
  > img {
    border-radius: 18px;
    min-height: 500px;
    object-fit: contain !important;
    background: ${(p) => p.bg || "transparent"};
  }

  > div {
    flex: 1;
    margin: 0 1%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 50px;
    margin: 0;
    width: 100%;
    text-align: center;

    > section {
      width: 90%;
      padding: 0 5%;
      text-align: center;

      > h2 {
        line-height: 45px;
        font-size: 35px;
      }
    }
  }
`;

export const PeopleFlexedData = styled.div`
    display:flex;
    justify-content:flex-end;
    gap:-20px;
    margin:2rem 0 0 0;
    padding:0 5%;
    position:relative;

    > section {
        width:40%;
        text-align:left;
        position:absolute;
        top:40%;
        left:10%;

        h2,h3 {
            font-style: normal;
            font-weight: 800;
            font-size: 35px;
            line-height: 25px;
            color: #000000;
        }

        h3 {
            font-size:10px;
            line-height: 40px;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.15px;
            color: #000000;
        }
    }

    @media (max-width:900px){
        flex-direction:column;
        gap:50px;

        > section {
            position:relative;
            left:0;
            top:0;
            width:100%;

            > h3 {
                line-height:45px;
            }
        }
    }
`

export const IconHolder = styled.div`
    width:80px;
    height:80px;
    border-radius:10rem;
    display:flex;
    align-items:center;
    justify-content:center;

    > img {
        width:4rem;
    }
`

interface ButtonProps {
    color?: string;
    border?: string;
}

export const TextButton = styled.div<ButtonProps>`
    display:flex;
    align-items:center;
    gap:10px;

    h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.15px;
        // text-decoration-line: underline;
        color: ${p => p.color || '#A2AD1F'};
        border: ${p => p.border || 'none'};
        border-radius:8px;
        padding:10px;
    }

    > i {
        margin: 0.4rem 0 0 0;
    }
`

interface CardSectionProps {
    bg?: string;
    imgSize?: string;
    margin?: string;
}

export const CardSection = styled.div<CardSectionProps>`
  margin: ${(p) => p.margin || "5rem 0"};
  background: ${(p) => p.bg || "var(--main-bg)"};
  justify-content: center;
  align-item: center;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  img {
    width: ${(p) => p.imgSize || "55%"};
    object-fit: cover;
  }

  @media (max-width: 728px) {
    margin: 0;
    img {
      width: 100%;
    }
  }
`;


interface VulnerabilityHeroHeaderWrapProp {
  bgImg?: string;
}
export const VulnerabilityHeroHeaderWrap = styled.section<VulnerabilityHeroHeaderWrapProp>`
    min-height:60vh;
    position:relative;
    background: #2A243180;
    align-items:top;
    display:flex;
    justify-content:left;
   // border: 1.5px solid;
  
  //  border-image: linear-gradient(180deg, #EA30F5 0%, #8C30F5 100%) 1;
   
    p
   {
    width:80%;
    
  

    }

    h2{
      width:150%
    }


   
   img
   {
  
    width:100%;
    padding-left:10%;
    padding-right:10%;
   }
    ::before{
        content: '';
        position:absolute;
        top:10rem;
        right:0;
        width:40%;
        max-width:800px;
        height:100%;
       // background: url(' ${(p) =>
          p.bgImg || "/images/vulnerabilityHeroIcon.png"}');
       // background-size:cover;

        
      //  background-repeat:no-repeat;
    
    }

    @media (max-width:920px){
        height:auto;
        h2{
     
      
          width:100%
        }
        p{
        
          width:100%;
          text-align:center;
        }
    
        > div
        {
          width:auto;
          padding-left:0px;
          margin-top:0px;
          
        }
    
        
        ::before{
            content: '';
            position:absolute;
            top:150PX;
            right:0;
            width:55%;
            height:100%;
            background: url(' ');
            background-size:cover;
            background-repeat:no-repeat;
            z-index
          }

        img
          {
            display:block;
            padding:0px;
          }
    }
`;


export const ItemCard = styled.div`
    background: #F1E4FF;
    padding:0.2rem 5% 2rem 5%;
    display:flex;
    align-items:Center;
    gap:40px;

    > div {
        width: 80%;
    }

    img {
        width:5.5rem;
    }

    h4 {
        font-weight: 700;
        font-size: 22px;
        letter-spacing: 0.15px;
    }

    span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.15px;
        line-height:30px;
        color: #000000;
    }

    @media (max-width:728px){
        width:100%;
        gap:40px 15px;

        > div {
            width:70%;
        }
    }
`