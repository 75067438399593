import React, { useState } from "react";
import {
  MainWrap,
  MainHead,
  LogoImage,
  HeaderBtn,
  MobileMenuController,
  MobileModal,
  LogoImageDiv,
  StartBtnDiv,
  StartBtnContent,
} from "../../styles/headerStyle";
import { Link, NavLink } from "react-router-dom";
import { Button, NavButton } from "../../styles/reusable";
import * as Icon from "react-feather";
import { useNavigate } from "react-router-dom";

interface Props {
  logo: string;
  hideContact: boolean;
  width?: string;
  top?: string;
  mobileWidth?: string;
}
const AppHeader = ({ logo, hideContact, width, top, mobileWidth }: Props) => {
  const navigate = useNavigate();
  const [showMobile, setShowMobile] = useState(false);

  return (
    <>
      <MainWrap>
        <MainHead>
          <Link to="/">
            <LogoImageDiv>
              <LogoImage
                width={width}
                mobileWidth={mobileWidth}
                top={top}
                src={logo}
                alt="sixth"
              />
            </LogoImageDiv>
          </Link>
          {hideContact ? null : (
            <HeaderBtn
            // to='/contact
            >
              <NavButton onClick={() => navigate("/")}>
                <Link to="/">Home</Link>
              </NavButton>
              {
                //  <NavButton  onClick={() => navigate("/products")}>
                //  <Link to="/products">Products</Link>
                //</NavButton>
              }

              <NavButton onClick={() => navigate("/pricing")}>
                <Link to="/pricing">Pricing</Link>
              </NavButton>

              <NavButton
                onClick={(e) => {
                  window.location.href = "https://blog.trysixth.com/";
                }}
              >
                Blogs
              </NavButton>

              <NavButton
                onClick={(e) => {
                  window.location.href = "https://docs.trysixth.com";
                }}
              >
                Documentation
              </NavButton>
              {}
            </HeaderBtn>
          )}
          <StartBtnDiv>
            <Link to="/pricing">
              <Button
                bg="var(--sixthLightPurple)"
                color="var(--sixthPurple)"
                hoverBg="var(--sixthPurple)"
                hoverColor="var(--text-black)"
                
              >
                Start free
              </Button>
            </Link>
          </StartBtnDiv>
          <MobileMenuController onClick={() => setShowMobile(true)}>
            <Icon.Menu size={45} strokeWidth={2} />
          </MobileMenuController>
        </MainHead>

        {showMobile ? (
          <MobileModal>
            <i>
              <Icon.X onClick={() => setShowMobile(false)} />
            </i>
            <Link to="/">
              <LogoImage
                width={width}
                mobileWidth={mobileWidth}
                top={top}
                src={logo}
                alt="sixth"
              />
            </Link>
            <div>
              <p
                className={"navigation-text"}
                onClick={() => {
                  setShowMobile(false);
                }}
              >
                <NavLink to="/">Home</NavLink>
              </p>
              {
                //<p
                //  className="navigation-text"
                //  onClick={() => {
                //    setShowMobile(false);
                //  }}
                //>
                //  {" "}
                //  <Link to="/products">Products</Link>
                //</p>
              }

              <p
                className="navigation-text"
                onClick={() => {
                  setShowMobile(false);
                }}
              >
                <Link to="/pricing">Pricing</Link>
              </p>
              <p
                className="navigation-text"
                onClick={() => {
                  setShowMobile(false);
                  window.location.href = "https://blog.trysixth.com/";
                }}
              >
                Blogs
              </p>

              <p
                className="navigation-text"
                onClick={() => {
                  setShowMobile(false);
                  window.location.href = "https://docs.trysixth.com";
                }}
              >
                Documentation
              </p>
              <div>
                <Button
                  bg="#F1E4FF"
                  color="#8C30F5"
                  onClick={(e) => navigate("/pricing")}
                >
                  Get started
                </Button>
              </div>
            </div>
          </MobileModal>
        ) : null}
      </MainWrap>
    </>
  );
};

export default AppHeader;
