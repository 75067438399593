import styled from "styled-components";

interface PropsArgs {
  bg?: string;
  color?: string;
  border?: string;
  hoverborder?: string;
  padding?: string;
  hoverColor?: string;
  hoverBg?: string;
  buttonWidth?: string;
}

interface FlexedAllAlignedProps {
  gap?: string;
  top?: string;
}

export const Button = styled.button<PropsArgs>`
  background: ${(p) => p.bg || "transparent"};
  color: ${(p) => p.color || "var(--text-black)"};
  border: ${(p) => p.border || "none"};
  padding: ${(p) => p.padding || "0.7rem 1.5rem"};
  min-width: ${(p) => p.buttonWidth || "160px"};
  min-height: 48px;
  font-size: 16px;
  font-weight: 600;
  transition:all .4s;
  &:hover {
    color: ${(p) => p.hoverColor || "var(--sixthPurple)"};
    background: ${(p) => p.hoverBg || "var(--sixthLightPurple)"};
    border: ${(p) => p.hoverborder || "none"};
  }
`;

export const NavButton = styled.div<PropsArgs>`
  background: ${(p) => p.bg || "transparent"};
  color: ${(p) => p.color || "black"};
  border: ${(p) => p.border || "none"};
  padding: ${(p) => p.padding || "0.7rem 1.5rem"};
  position: relative;
  margin: 0 2px;
  overflow: hidden;
  font-weight: 600;
  ::before {
           
      transition: 0.1s transform linear, 0.1s height linear 0.1s;
      transform: translateX(0);
      height: 100%;
      border-color: var(--sixthPurple);
          
  }
  &:hover {
        
    ::before {
           
      transition: 0.1s transform linear, 0.1s height linear 0.1s;
      transform: translateX(0);
      height: 100%;
      border-color: var(--sixthPurple);
          
    }
    ::after {
      transition: 0.1s transform linear 0.2s, 0.1s height linear 0.3s;
      transform: translateX(0);
      height: 100%;
      border-color: var(--sixthPurple);
          
    }
  }
  ::before {
    .box-sizing;         
    transform: translateX(100%);
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 5px;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    cursor:Pointer;
  }
     
    ::after {
    .box-sizing;
    transform: translateX(-100%);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 5px;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    cursor:Pointer;
  }
  

 
`;
export const FlexedAllAligned = styled.div<FlexedAllAlignedProps>`
  display: flex;
  align-items: center;
  position: relative;
  gap: ${(p) => p.gap || 0};
  top: ${(p) => p.top || 0};
`;

export const GenericTextHeader = styled.h1`
  font-size: 45px;
  font-weight: 700;
`;

export const Centered = styled.div`
  width: 80%;
  margin: 4rem auto;
  

  > h1 {
    font-size: 36px;
    text-align: center;
  }

  > h2 {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
  }

  @media (max-width: 728px) {
    width: 90%;
  }
`;

export const PriceCardFlex = styled.div`
  display: flex;
  justify-content: Center;
  gap: 40px;
  margin: 3rem 0 0 0;
 
  > div {
    width: 45%;
    border: 1px solid #8c30f5;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 25%);
    padding: 10px 10px 20px 10px;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(0deg, #18191f, #18191f);

    > h5 {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: left;

      > span {
        font-size: 16px;
        font-weight: 600;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    > h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    span {
      color: #757575;
    }

    > div {
      display: flex;
      align-items: Center;
      justify-content: Center;
      gap: 5px;
      padding: 5px 0;
      font-size: 15px;
    }
  }

  @media (max-width: 728px) {
    flex-direction: column;

    > div {
      width: 95%;
      padding: 10px 2.5% 30px 2.5%;
    }
  }
`;
