import styled from "styled-components";

export const VsCodeParent = styled.div`
  display: flex;
`;

export const VsCodeMain = styled.div`
  position: relative;
  min-width: 50%;
  width: 50%;
`;
export const VsCodefixMain = styled.div`
  max-height: 500px;
  min-width: 70%;
  position: absolute;

  overflow-y: scroll;
  top: 4rem;
  border-radius: 8px;
  background: #1b1e31;
  border: 2px solid #2b3371;
  align-items: top;
  color: #c1cbe6;
  padding-top: 2rem;
  > p {
    padding: 0 2rem;
    margin: 0px;
  }
`;

export const VsCodeFixSection = styled.div`
  display: flex;
  margin-bottom: 0px;
  padding-left: 1rem;
  gap: 2rem;

  > p {
    min-width: 4rem;
    cursor: pointer;
    margin: 0;
    padding: auto 5rem;
    color: #445197;
  }
`;
export const VsCodeLine = styled.div`
  display: flex;
  margin-left: 2rem;
  justify-content: center;


  > p {
    min-width: 4rem;
    cursor: pointer;
    color:#3C4154;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  line{
    min-width: 4rem;
    cursor: pointer;
    color:#3C4154;
    margin-bottom: -10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

interface codeProp {
  isFix?: boolean;
  isWrong?: boolean;
}

export const VsCode = styled.div<codeProp>`
  display: flex;
  width: 100%;
  background: ${(p) =>
    p.isFix ? "#333F35 " : p.isWrong ? "#43151F  " : "transparent"};
  gap: 0;
  text-decoration-line: underline;
  text-decoration-color: #3d5887;
  text-decoration-style: ${(p) => (p.isFix ? "none" : "wavy")};
  h2 {
    text-decoration-line: underline;
    text-decoration-color: #3d5887;
    text-decoration-style: ${(p) => (p.isFix ? "none" : "wavy")};
  }
  p.line {
    margin-top: -10rem;
  }
  p {
    
    font-size: 20px;
    font-weight: 500;
    line-height: 0px;
    letter-spacing: 0em;
    text-align: left;
  }
  @media (max-width:1550px){
    p {
    
    font-size: 14px;
    line-height: 0px;
    letter-spacing: 0em;
   
  }
  
  

}
`;
