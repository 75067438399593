import styled from "styled-components";

export const MainWrap = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0;
  position: relative;
  z-index: 10000;
  background: white;
`;

interface LogoProps {
  width: any;
  top: any;
  mobileWidth: any;
}

export const LogoImageDiv = styled.div`
  width: 230px;
  --r: 20px; /* the radius */
  border-top-left-radius: var(--r);
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: end !important;
  padding-right: 24px;
  padding-left: 24px;
  height:70px;

  img {
    z-index: 1;
    position:absolute;
    left:50px;
    :hover {
      transition: all 0.6s;
    }
  }
  ::before {
    inset: 0 20px 0 0px;
    border-top-right-radius: var(--r);
    content: "";
    position: absolute;
    background: black;
    transform: skew(20deg);
    transform-origin: bottom left;
    z-index: 1;
  }
  ::after {
    content: "";
    position: absolute;
    background: black;
    transform: skew(20deg); /* adjust this to control the inclination */
    transform-origin: bottom left;
    right: 1px;
    bottom: 0;
    width: var(--r);
    aspect-ratio: 1;
    -webkit-mask: radial-gradient(100% 102% at  100% 0, #0000 97%, #000);
  }
`;

export const LogoImage = styled.img<LogoProps>`
  width: ${(p) => p.width || "5rem"};

  @media (max-width: 450px) {
    width: ${(p) => p.mobileWidth || "3rem"};
  }
  color: white;
`;

interface PropsArgs {
  mobileBg?: string;
  mobileColor?: string;
}

export const NavDiv = styled.div`
  width: 150px;
  height: 0;
  border: 12px;
  border-top: 70px solid black;
  border-right: 30px solid transparent;
`;

export const MainHead = styled.nav<PropsArgs>`
  width: 100%;
  margin: 2px 0px 0rem -px;
  display: flex;
  align-items: Center;
  justify-content: space-between;

  background: white;

  > div {
    display: flex;
    align-items: Center;
    justify-content: center;
    gap: 100px;
  }

  @media (max-width: 728px) {
    > div {
      display: none;
    }
  }

  @media (max-width: 450px) {
    background: ${(p) => p.mobileBg || "transparent"};
    color: ${(p) => p.mobileColor || "var(--main-bg)"};
  }
`;

export const MobileMenuController = styled.section<PropsArgs>`
  display: none;
  color: ${(p) => p.mobileColor || "black"};

  @media (max-width: 728px) {
    display: block;
  }
`;

export const MobileModal = styled.div`
  position: fixed;
  width: 86%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--main-bg);
  padding: 3rem 7%;
  z-index:4;

  > i {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
`;

export const NavigationSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 168%;
    letter-spacing: 0.005em;
    color: #3e3e0d;
  }

  @media (max-width: 728px) {
    display: none;
  }
`;

export const HeaderBtn = styled.section`
  display: flex;
  justify-content:space-evenly;

  > button {
    font-weight: 700;
  }

  @media (max-width: 728px) {
    display: none;
  }
`;

export const ActionInput = styled.div`
  display: flex;
  align-items: Center;
  width: 25rem;
  margin: 2rem 0 0 0;

  > input {
    border: none;
    background: #f4f5f7;
    width: 60%;
    padding: 0.9rem;
    border-radius: 5px 0 0 5px;
  }

  > button {
    border: none;
    width: 40%;
    padding: 0.9rem;
    border-radius: 0 5px 5px 0;
  }

  @media (max-width: 728px) {
    width: 100%;

    > button {
      font-size: 10px;
      height: 51px;
    }
  }
`;

export const StartBtnDiv = styled.div`
  --r: 20px; /* the radius */

  width: 240px;
  height: 100%;
  border-top-right-radius: var(--r);
  position: relative;
  overflow: hidden;
  background: transparent;
  display:flex;
  justify-content:end !important;
  padding-right:24px;

  button {
    z-index: 10;
    box-shadow: 2px black;
    position: relative;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 20px;
    transition: all 0.8s;
    padding:0.2rem 1rem !important;
    min-height:45px !important;
    :hover {
      transition: all 0.6s;
    }
  }
  ::before {
    inset: 0 0 0 20px;
    border-top-left-radius: var(--r);
    content: "";
    position: absolute;
    background: black;
    transform: skew(-20deg);
    transform-origin: bottom right;
    z-index: 4;
  }
  ::after {
    content: "";
    position: absolute;
    background: black;
    transform: skew(-20deg); /* adjust this to control the inclination */
    transform-origin: bottom left;
    left: 1px;
    bottom: 0;
    width: var(--r);
    aspect-ratio: 1;
    -webkit-mask: radial-gradient(100% 102% at 0 0, #0000 97%, #000);
  }
`;

export const StartBtnContent = styled.div``;
