import styled from "styled-components";

export const  FeedbackMainDiv = styled.div`

`
export const FeedbackSection = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
`


export const FeedbackField = styled.div`
width:50%;
@media (max-width: 550px) {
    width: 90%;
   
  }

`
interface FeedbackFieldInputProp {
    height?:string
}
export const FeedbackFieldInput = styled.input<FeedbackFieldInputProp>`
background:transparent;
color:white;
width:90%;
padding:1rem;
border: 1px solid #F1E4FF;
border-radius:5px;
height: ${(p) => p.height || "3rem"};

`
