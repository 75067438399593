import React from "react";
import "./styles/App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";

import { initializeApp } from "firebase/app";

import RedirectPage from "./pages/RedirectPage";
import Faq from "./pages/Faq";
import Feedback from "./pages/feedback";
import VSPricing from "./pages/extensionpriciong";
function App() {
  const firebaseConfig = {
  
      apiKey: "AIzaSyDpqU2toL-ZNzAYN7fflqqtYamWXp22Lm0",
      authDomain: "sixth-v2.firebaseapp.com",
      databaseURL: "https://sixth-v2-default-rtdb.firebaseio.com",
      projectId: "sixth-v2",
      storageBucket: "sixth-v2.appspot.com",
      messagingSenderId: "971026243533",
      appId: "1:971026243533:web:a047ecc7d6e60a8a42ee84",
      measurementId: "G-2W6SFLPJ7B"
    
  };

   initializeApp(firebaseConfig);
   
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />  
          <Route path="/pricing" element={<VSPricing />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/feedback" element={<Feedback/>} />          
          <Route path="/vsPricing" element={<VSPricing/>} />
          <Route path="*" element={<RedirectPage />} />
        </Routes>
      </Router>
     
    </>
  );
}

export default App;
