import styled from "styled-components";

interface StepDivProp {
  bgImg?: string;
  left?: string;
  right?: string;
}
export const StepDiv = styled.div<StepDivProp>`
  width: 30%;
  flex-grow:1;
  border: 0.5px solid #8c30f5;
  min-height: 10vh;
  border-radius: 1.5rem;
  background-color: rgba(0, 0, 0, .5) !important;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width:400px;
  backdrop-filter: blur(30px);
   background: linear-gradient(black, #0d1117) padding-box,
    linear-gradient(
        103.55deg,
        #7152dc 16.12%,
        #2886a9 41.87%,
        rgba(63, 122, 144, 0.53) 84.45%
      )
      border-box;
border:1px solid transparent;






padding: 1rem 2rem  2rem ;
  > img {
    width: 70px;
    height: 70px;
  }
  > div {
    padding-top: 5rem;
  }
   h1{
    font-size:3rem;
    min-height:100px;
    font-weight:500;
    letter-spacing:-0.7px;
    line-height:1.2em;
    text-align:left;
    text-transform:capitalize;
    font-family: "Hubot Sans", "Hubot SansHeaderFallback", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  
  }
  p{
    font-size:1.5rem;
    font-weight:500;
    color:rgb(139, 148, 158);
    letter-spacing:-0.48px;
    line-height:1.5em;
    text-align:left;
    text-transform:cappitalize;
    font-family: "Mona Sans", "MonaSansFallback", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"
  }
  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 90%;
    height: 60%;
    background: url("${(p) => p.bgImg}");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -30;
  }
  @media (max-width: 1200px) {
   min-width:60%;
  }

  @media (max-width: 840px) {
    min-width:70%;
   min-height: 50vh;

    ::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 60%;
       
   }
`;

export const StepHeroImgDiv = styled.div`

  display: flex;
  align-items: end;
  justify-content: end;
  padding-bottom: 0;
  margin-bottom: 0;
  position: relative;
  height: 100%;
  
  > img {
    
    width: 100%;
    height: 90%;
  }

  @media (max-width: 840px) {
    > img {
      
      width: 100%;
      height: 70%;
    }
`;

export const StepHero = styled.div`
  display: flex;
  gap: 35px;
  justify-content: start;
  margin: 1rem 2rem 3rem;

  overflow: scroll;
  scrollbar-width: none;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    background: transparent;
    width: 0px;
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background: #a9a8a8;
    cursor: pointer;
    width: 0px;
  }
  @media (max-width: 900px) {
    gap: 50px;
    margin: 0.5rem;
    text-align: center;
    > h3 {
      font-size: 2em;
    }
  }
`;

export const StepMainDiv = styled.div`
  display: flex;
  justify-content: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
  margin: 5rem auto;
  padding: 0 0%;
  background: rgb(1, 4, 9);

  text-align: left;

  border-radius: 1.5rem;
  min-width: 360px;
  max-width: 1300px;

  h3 {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.03em;
  }

 

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 50px;
    margin: 0;
    width: 100%;
    text-align: center;
    > h3 {
      font-size: 2em;
    }
  }
`;
