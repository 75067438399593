import React, { useEffect, useState } from "react";
import Footer from "../components/reusable/footer";
import AppHeader from "../components/reusable/header";
import { Centered } from "../styles/reusable";
import { PriceCardButton, PriceCardIcon, PriceCardSection, PriceDurationSection, PriceDurationSpan, PriceInfoSpan, PriceItems, PriceMainDiv, PriceSection, PriceYearSection, PricingCard } from "../styles/pricing";
import { TextHeader } from "../styles/styles";

const VSPricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const openLinkInNewTab = () => {
  //   window.open("https://calendly.com/ope-trysixth/30min", "_blank");
  // };
  const [planPick, setPlanPick] = useState<Array<boolean>>([true, true, true])

  const changePlan = (index: number, val: boolean) => {

    const plan = []
    plan.push(...planPick)

    plan[index] = val
    setPlanPick(plan)

  }
  return (
    <>
      <div className="main-widget">
        <PriceMainDiv>
          <AppHeader
            hideContact={false}
            logo={"/images/sixthWhiteIcon.png"}
            top="0.5rem"
            width="10rem"
            mobileWidth="8rem"
          />
          <Centered>
            <h1>Pricing</h1>
            <h2>

              Ready to get &nbsp;
              <span
                style={{
                  borderBottom: "1px solid #8C30F5",
                }}
              >
                started?
              </span>{" "}
              Choose your VS Code plan below.
            </h2>
            {PricingData.map((item, index) => (
              <PriceSection>
                <PriceCardSection itemPos="center">
                  <div>
                    <PriceCardIcon
                      imgWidth="70px"
                      imgHeight="60px"
                      alt="img"
                      src={item.HeaderImage}
                    />
                    <TextHeader>{item.Header}</TextHeader>
                  </div>
                </PriceCardSection>

                <PriceItems>
                  {item.card.map((card, index) => (
                    <PricingCard>
                      {
                        card.info ?
                          <PriceInfoSpan>
                            {card.info}

                          </PriceInfoSpan> : <></>
                      }



                      <PriceCardSection style={{ alignItems: "center" }}>
                        <h1>
                          {card.Plan}


                        </h1>

                        {
                          card.YearPrice ? (<><PriceDurationSection>
                            <div> <PriceDurationSpan active={planPick[index]} onClick={(e) => {
                              changePlan(index, true)
                            }}>
                              Month
                            </PriceDurationSpan>
                              <PriceDurationSpan active={!planPick[index]} onClick={(e) => {
                                changePlan(index, false)
                              }}>
                                Year
                              </PriceDurationSpan>


                            </div>

                          </PriceDurationSection>
                            {
                              planPick[index] ? <PriceYearSection>

                                <h5>

                                  <span >
                                    {card.Price}


                                  </span>
                                  <span className="year" >Month</span>
                                </h5>

                              </PriceYearSection> :
                                <PriceYearSection>

                                  <h5>
                                    <span className="cancled">
                                      {card.formerYearPrice}


                                    </span>
                                    <span >
                                      {card.YearPrice}


                                    </span>
                                    <span className="year" >year</span>
                                  </h5>

                                </PriceYearSection>
                            }

                          </>

                          ) : (<></>)
                        }






                        <h2> {card.brief}</h2>


                      </PriceCardSection>


                      {
                        card.button?.map((btn, index) =>
                        (
                          <PriceCardButton
                            bg={btn.bg}
                            color={btn.color}
                            onClick={(e) => btn.onClick()}
                          >
                            {btn.text}
                          </PriceCardButton>
                        ))
                      }

                      <PriceCardSection>
                        {card.benefits.map((benefit, index) => (
                          <div>
                            <PriceCardIcon
                              alt="img"
                              src="/images/ph_seal-check.svg"
                            />
                            {benefit}
                          </div>
                        ))}
                      </PriceCardSection>





                      <br />
                      <br />
                    </PricingCard>
                  ))}
                </PriceItems>
              </PriceSection>
            ))}
          </Centered>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",

            }}
          >

          </div>

          <Footer />
        </PriceMainDiv>
      </div>
    </>
  );
};

export default VSPricing;


const PricingData = [
  {
    Header: "VS code Extension",
    HeaderImage: "/images/akar-icons_vscode-fill.svg",
    card: [
      {
        PlanImage: "",
        Plan: "Individual",
        PlanColor: "#4FB06D",
        Price: "$9.99/",
        YearPrice: "$49.99/",
        formerYearPrice: "$119.99/",
        Frequency: "month",
        brief: "",
        info: "most popular",
        button: [
          {
            color: "var(--sixthPurple)",
            bg: "transparent",
            text: "install",
            onClick: () => {
              window.open("https://marketplace.visualstudio.com/items?itemName=Sixth.sixth&ssr=false#overview", "_blank");

            },
          }
        ],
        benefits: [
          "ai auto complete",
          "Real time vulnerability detection.",
          "In built assistant chat.",
          "Support via discord.",

        ],
      },
      {
        PlanImage: "",
        Plan: "Team Plan",
        PlanColor: "#4FB06D",
        Price: "$29.99/  ",
        Frequency: "Month",
        brief: "",
        YearPrice: "$199.99",
        formerYearPrice: "$299.99",
        button: [
          {
            color: "var(--sixth-white)",
            bg: "var(--sixth-gradient3)",
            text: "start free trial ",
            onClick: () => {
              window.open("https://marketplace.visualstudio.com/items?itemName=Sixth.sixth/signUp", "_blank");



            },
          },
          {
            color: "var(--sixthPurple)",
            bg: "transparent",
            text: "schedule demo",
            onClick: () => {
              window.open("https://calendly.com/ope-trysixth/30min", "_blank");

            },
          }
        ],
        benefits: [
          "Up to 5 users.",
          "Real time vulnerability detection.",
          "Personalized assistant chat..",
          "Jira integration, auto recommend code implementation to Jira.",

        ],
      },
      {
        PlanImage: "",
        Plan: "Enterprise",
        PlanColor: "#4FB06D",
        Price: "",
        Frequency: "",
        brief: "Let’s talk",
        YearPrice: "",
        formerYearPrice: "",
        button: [
          {
            color: "var(--sixth-white)",
            bg: "var(--sixth-gradient3)",
            text: "contact us",
            onClick: () => {

              var body = "Dear sixth, I hope this email finds you well. My name is [Your Name], and I am reaching out to discuss [specific topic or purpose]. I believe that a conversation between us could be mutually beneficial. Would you be available for a discussion? If so, I would appreciate it if we could find a suitable time to connect. Please let me know your availability, and I will do my best to accommodate your schedule. Thank you for considering my request. I look forward to hearing from you.Best regards, [Your Full Name] [Your Contact Information]"
              const mailtoLink = `mailto:ope@trysixth.com?subject=schedule Meeting&body=${encodeURIComponent(body)}`
              window.location.href = mailtoLink

            },
          }
        ],
        benefits: [
          "Unlimited users.",
          "Slack app to track detected vulnerability.",
          "Advanced dashboard.",
          "GitHub integration. ",
          "Jira integration.",
          "Azure devops integration",
          "AWS Integration",
          "Dedicated support channels",
        ],
      },
    ],
  },

];

