import React from "react";

import { Fade, Zoom } from "react-awesome-reveal";
import { LeftHero, RightHero, SupplText, TextHeader } from "../../styles/styles";
import { LanguageDiv, LanguageDivMarque, LanguageDivMarqueItem, VsHeroMain } from "../../styles/products";
import Testimonial from "./testimonial";
import { FaPython } from "react-icons/fa";
import { FaJsSquare } from "react-icons/fa";
import { FaVuejs } from "react-icons/fa";
import { FaGolang } from "react-icons/fa6";
import { DiNodejs } from "react-icons/di";
import { DiCodeigniter } from "react-icons/di";
import { DiJava } from "react-icons/di";

import { DiDjango } from "react-icons/di";




import {
  FaqFieldMain,
  
} from "../../styles/faqStyles/faqCompStyles";
import Faqs from "../faqComps/faqs";

const DemoSection = () => {
  
  
  return (
    <div>
      <Fade duration={3000}>
        <VsHeroMain height="30vh" imageWidth="400px">
          <section>
            <LeftHero>
              <img src="/images/core3.jpg" alt="hacker" />
            </LeftHero>
            <RightHero>
              <div>
                <TextHeader>
                  AI code fixes that integrate
                  <br /> smoothly with your
                  <br /> workflow.
                </TextHeader>
                <SupplText>
                  Effortless code fixes seamlessly enhance your workflow without
                  <br />
                  disrupting functionality.
                </SupplText>
                <p> view product - </p>
              </div>
            </RightHero>
          </section>
        </VsHeroMain>
      </Fade>
      <Fade duration={2500}>
        <LanguageDiv>
          <section>
            <h1>Multi language support</h1>
            <p>
              <SupplText>
                We support a wide range of programming languages and are
                currently expanding.
              </SupplText>
            </p>
          </section>
          <LanguageDivMarque>
            <LanguageDivMarqueItem>
              <FaPython />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <FaJsSquare />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <FaVuejs />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <FaGolang />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <DiNodejs />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <DiJava />
            </LanguageDivMarqueItem>{" "}
            <LanguageDivMarqueItem>
              <DiCodeigniter />
            </LanguageDivMarqueItem>{" "}
            <LanguageDivMarqueItem>
              <DiDjango />
            </LanguageDivMarqueItem>
          </LanguageDivMarque>
          <LanguageDivMarque left="60%">
            <LanguageDivMarqueItem>
              <FaPython />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <FaJsSquare />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <FaVuejs />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <FaGolang />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <DiNodejs />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <DiJava />
            </LanguageDivMarqueItem>{" "}
            <LanguageDivMarqueItem>
              <DiCodeigniter />
            </LanguageDivMarqueItem>{" "}
            <LanguageDivMarqueItem>
              <DiDjango />
            </LanguageDivMarqueItem>
          </LanguageDivMarque>
          <LanguageDivMarque left="100%">
            <LanguageDivMarqueItem>
              <FaPython />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <FaJsSquare />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <FaVuejs />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <FaGolang />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <DiNodejs />
            </LanguageDivMarqueItem>
            <LanguageDivMarqueItem>
              <DiJava />
            </LanguageDivMarqueItem>{" "}
            <LanguageDivMarqueItem>
              <DiCodeigniter />
            </LanguageDivMarqueItem>{" "}
            <LanguageDivMarqueItem>
              <DiDjango />
            </LanguageDivMarqueItem>
          </LanguageDivMarque>
        </LanguageDiv>
      </Fade>

      <FaqFieldMain>
        <TextHeader> Frequently asked questions </TextHeader>

        <div style={{ maxWidth: "1300px", width: "100%" }}>
          {FaqsData.map((item, index) => (
            <>
              <Faqs title={item.question} answer={item.answer} />
            </>
          ))}
        </div>
      </FaqFieldMain>
      <Testimonial />
      
    </div>
  );
};

export default DemoSection;

const FaqsData = [
  {
    question:"What is Sixth SAST?",
    answer:"Sixth SAST is a Static Application Security Testing tool that is an extension for Visual Studio Code. It utilizes GPT4-powered AI to enhance code and application security for developers.",
    
  },
  {
    question:"How does Sixth SAST enhance code and application security?",
    answer:"Sixth SAST enhances code and application security by scanning and diagnosing scripts as they are being written in real-time. The tool identifies potential security loopholes and presents fixes that can help mitigate the risk of exploitation and enhance the overall security of the application.",
    
  },
  {
    question:"What type of AI powers Sixth SAST?",
    answer:"Sixth SAST uses GPT4 (Generative Pretrained Transformer 4), an artificial intelligence model, for its core operations. This AI model carries out real-time security checks, identifies security loopholes, and provides fixes.",
    
  },
  {
    question:"How does Sixth SAST identify potential security vulnerabilities?",
    answer:"Sixth SAST identifies potential security vulnerabilities through the use of GPT4 AI, which scans written code in real-time. It can spot weak cryptography, command injections, SQL and LDAP add-ons, weak randomness and randomization, cross-site scripting and request forgery, and other potential cyber threats.",
    
  }
]