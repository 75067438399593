import styled, { keyframes } from "styled-components";


export const VsHero = styled.div`
  background: linear-gradient(
    148.74deg,
    #d55bc8 33.92%,
    #9139f0 87.62%,
    rgba(145, 57, 240, 100) 87.62%
  );
`;

interface VsHeroMainProp {
  bg?: string;
  imageWidth?: string;
  imageHeight?: string;
  imagePadding?: string;
  alignItems?: string;

  height?: string;
  width?: string;
  marginTop?: string;
  marginBottom?: string;
  padding?: string;
  border?: string;
}
export const VsHeroMain = styled.section<VsHeroMainProp>`
  border: ${(p) => p.border || ""};
  margin-top: ${(p) => p.marginTop || "auto"};
  margin-bottom: ${(p) => p.marginTop || "0rem"};
  gap: 0px;
  display: flex;
  text-align: left;
  justify-content: center;
  background: ${(p) => p.bg || "#191e24"};
  min-height: ${(p) => p.height || "auto"};
  min-width: ${(p) => p.width || "85%"};
  margin: 0 auto;
  > section {
    margin: 5rem 0;
    border-radius: 1.5rem;
    background-color: rgba(0, 0, 0, 0.5) !important;
    min-height: 100px;
    max-width: 1300px;
    background: red;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    padding: 56px;
    position: relative;
    display: flex;
    text-align: left;
    justify-content: start;
    align-items: ${(p) => p.alignItems || "center"};
    justify-content: space-between;
    ::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: -1px;
      pointer-events: none;
      content: "";
      border: 1.5px solid rgba(255, 255, 255, 0.12);
      border-radius: inherit;
      -webkit-mask-image: linear-gradient(135deg, #fff 0%, transparent 50%);
      mask-image: linear-gradient(135deg, #fff 0%, transparent 50%);
    }
  }

  border-image: linear-gradient(180deg, #ea30f5 0%, #8c30f5 100%) 1;
  > div {
    margin: 0px;
  }

  img {
    height: ${(p) => p.imageHeight || "450px"};
    width: ${(p) => p.imageWidth || "40rem"};
    padding-top: ${(p) => p.imagePadding || "auto"};
    border-radius: 1.2rem;
    object-fit: cover;
  }

  @media (max-width: 600px) {
    margin: 0rem auto 0 auto;
    padding: 2rem 0%;

    > h2 {
      font-size: 25px;
      line-height: 35px;
      width: 90%;
    }

    img {
      height: auto;
      width: 100%;
    }

    > p {
      padding: 0 5%;
    }
  }

  @media (max-width: 1440px) {
    gap: 30px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    width: auto;
    text-align: center;
    gap: 0;
    padding: auto;
    margin: 1rem;
    > img {
      width: 100%;
    }
  }
`;


export const LanguageDiv = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 400px;
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  padding: 130px 0;
  p {
    width: 60%;
  }
  h1 {
    font-weight: 800;
    font-size: 4rem;
    padding: 0;
    margin: 0;
    transition: all 0.8s;
    line-height: 1em;
  }

  > section {
    max-width: 1300px;
    width: 100%;
    padding: 3rem 1rem;
    
  }
`;


interface marqueeProp{
  left?:string,

}

export const LanguageDivMarque = styled.div<marqueeProp>`
  position: absolute;
  height: 100px;
  width: 90%;
  background: red;
  bottom:  ${(p) => p.left|| "10%"};
  background: #010409;

  right: 0;
  z-index: -1;
  transform: rotateZ(-49deg);
  margin-inline: auto;

  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 100%,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0)
  );

  &:before,
  &:after {
    position: absolute;
    top: 0;
    width: 0%;
    height: 100%;
    content: "";
    z-index: 1;
  }

  &:before {
    left: 0;
    background: linear-gradient(to right, #111 0%, transparent 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(to left, #111 0%, transparent 100%);
  }

  :hover {
    > div {
      animation-play-state: paused;
    }
  }
`;


const scrolling = keyframes`
  to { left: -200px }
`;

export const LanguageDivMarqueItem = styled.div`
  --units: 1;
  position: absolute;
  height: 90px;
  width: 90px;
  bottom: 0;
  right: 0;
  transform: rotateZ(49deg) ;
  display: flex;
  justify-content: center;
  align-items: center;
  left: max(calc(200px * 8), 100%);
  z-index: -1;
  animation-name: ${scrolling};
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: calc(10s / 8 * (8 - var(--units)) * -1);
  > svg {
    width: 50px;
    height: 50px;
  }
  &:nth-child(2) {
    --units: 2;
  }
  &:nth-child(3) {
    --units: 3;
  }
  &:nth-child(4) {
    --units: 4;
  }
  &:nth-child(5) {
    --units: 5;
  }

  &:nth-child(6) {
    --units: 6;
  }

  &:nth-child(7) {
    --units: 7;
  }

  &:nth-child(8) {
    --units: 8;
  }
`;





