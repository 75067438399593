import FaqComponent from "../components/faqComps/faqComp";

const Faq = () => {
  return (
    <>
    
  <FaqComponent />
    </>
  );
};

export default Faq;
