import { Fade } from "react-awesome-reveal";
import {
  VsCode,
  VsCodeFixSection,
  VsCodeLine,
  VsCodeMain,
  VsCodeParent,
  VsCodefixMain,
} from "../../styles/homeComp/vsCodePage";
import { useState } from "react";

const VsCodePageComp = ({
  lines = [
    {
      line: "1",
      isWrong: true,
      fixed: false,
      code: [
        {
          color: "green",
          text: "thi is code ",
          marginLeft: "Opx",
          marginRight: "0px",
        },
      ],
    },
  ],
  fixes = [""],
  codeFix = [
    {
      line: "1",
      isWrong: true,
      fixed: false,
      code: [
        {
          color: "green",
          text: "thi is code ",
          marginLeft: "Opx",
          marginRight: "0px",
        },
      ],
    },
  ],
}) => {
  const [visible, setVisible] = useState(false);
  const [isFix, setFix] = useState(false);

  const openFixPage = () => {
    setFix(true);
    setVisible(false)
    
  };
  const openFixPopUp=()=>
  {
    if(!isFix)
    {
      setVisible(true)
    }


  }
//  const closeFixPopUp=()=>
//  {
//    setVisible(false)
//
//  }
//  const openProblem = () => {};
  return (
    <>
      <VsCodeParent>
        <VsCodeMain>
          {lines.map((item, index) => (
            <VsCodeLine
              onMouseEnter={ () => openFixPopUp()}
              onMouseLeave={() => setVisible(false)}
            >
              <p style={{
                gap:"0px"
              }}className="line">
                {item.line}  {
                item.isWrong && isFix ?<>-</>:
                <></>

              }</p>
            
              <VsCode
              isWrong={item.isWrong && isFix }
              >
                {item.code.map((codeItem, index) => (
                    <p
                    style={{
                      color: codeItem.color,
                      marginLeft: codeItem.marginLeft,
                      fontWeight:"600",
                    }}
                  >
                    {codeItem.text+" "}
                  </p>
                  
                  
                ))}
              </VsCode>
            </VsCodeLine>
          ))}

          {visible && fixes != null ? (
            <Fade duration={450}>
               <VsCodefixMain
              onMouseEnter={() => openFixPopUp()}
              onMouseLeave={() => setVisible(false)}
            >
              {
                fixes.map((items,index) => (
                  <p style={{fontSize:"14px"}}>{items}</p>
                  

                ))
              }
           
              <hr color="#2b3371" />
              
              <VsCodeFixSection>
                <p style={{fontSize:"14px"}}>View Problem( F8)</p>
                <p style={{fontSize:"14px"}}
                  onClick={() => {
                    openFixPage();
                  }}
                >
                Quick Fix( )
                </p>
              </VsCodeFixSection>
            </VsCodefixMain>
            </Fade>
           
          ) : (
            <></>
          )}
        </VsCodeMain>

        {isFix ? (
          
          <VsCodeMain>
            {codeFix.map((item, index) => (
              <VsCodeLine>
                <p>{item.line}  {
                item.isWrong && isFix ?<>+</>:
                <></>

              }</p>

                <VsCode isFix={true} >
                    {item.code.map((codeItem, index) => (
                      <p
                        style={{
                          color: codeItem.color,
                          marginLeft: codeItem.marginLeft,
                          marginRight: codeItem.marginRight
                         
                        }}
                      >
                        {codeItem.text}
                      </p>
                    ))}
                 
                </VsCode>
              </VsCodeLine>
            ))}
          </VsCodeMain>
        
         
        ) : (
          <></>
        )}
        
      </VsCodeParent>
    </>
  );
};

export default VsCodePageComp;
