import React, { useEffect } from "react";
import {
  HeroHeaderWrap,
  LeftHero,
  TextHeader,
  SupplText,
} from "../../styles/styles";

import { IoMdClose } from "react-icons/io";

import { Button } from "../../styles/reusable";
import AppHeader from "../reusable/header";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaCode } from "react-icons/fa6";

import { Fade, Bounce } from "react-awesome-reveal";
import {
  HomeHeroDiv,
  HomeHeroDivContent,
  HomeHeroLeft,
  HomeHeroMainDiv,
  HomeHeroMainHeadText,
  HomeHeroMainLinkButton,
  HomeHeroRight,
  HomeHeroRightBars,
  HomeHerorRound,
  ImgHero,
  RotatingSpan,
  VsCodeImg,
  VsDemoContent,
  VsDemoMain,
  VsFileTop,
  VsTop,
} from "../../styles/homeComp/home";
import VsCodePageComp from "./vsCodePage";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { MdArrowOutward } from "react-icons/md";
import Services from "./services";
import Tools from "./tools";
import TrustedComponent from "./trustedComponents";

const HomeHero = () => {
  const handleClickScroll = () => {
    const element = document.getElementById("vs-code-demo");
    if (element) {
      // Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const registerUser = () => {
    window.open(
      "https://marketplace.visualstudio.com/items?itemName=Sixth.sixth",
      "_blank"
    );
  };
  useEffect(() => {
    const root = document.documentElement;

    const marqueeElementsDisplayed = 4;
    const marqueeContent = document.querySelector("ul.marquee-content");
    if (marqueeContent == null) return;
    console.log(marqueeElementsDisplayed);

    root.style.setProperty(
      "--marquee-elements",
      marqueeContent.children.length.toString()
    );

    for (let i = 0; i < marqueeElementsDisplayed; i++) {
      marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
    }
  }, []);

  return (
    <>
      <HeroHeaderWrap>
        <AppHeader
          hideContact={false}
          logo={"/images/sixthWhiteIcon.png"}
          top="0.5rem"
          width="10rem"
          mobileWidth="8rem"
        />
        <HomeHeroMainDiv>
          <HomeHeroMainHeadText>
            <h1>Increase &nbsp;&nbsp;productivity</h1>
            <div className="span">
              <div>
                <h1>with ai powered code assistant</h1>
              </div>
              <span>
                <p onClick={(e) => registerUser()}>get started</p>

                <HomeHeroMainLinkButton>
                  <MdArrowOutward />
                </HomeHeroMainLinkButton>
              </span>
            </div>
          </HomeHeroMainHeadText>
          <HomeHeroDivContent>
            <HomeHeroRight>
              <ImgHero mainWidth="100%">
                <VsCodeImg src="/images/code.png" alt="Sixth"></VsCodeImg>
              </ImgHero>
              <div className="end-line">
                <div className="left">
                  <div className="code">
                    <FaCode />
                  </div>
                  <span>
                    <span className="bold"> 46</span>%
                  </span>
                  <span className="complete">Code written</span>
                </div>
                <div>
                  <span>
                    {" "}
                    More than <span className="auto">auto</span>|
                  </span>
                  <span className="complete fade">complete</span>
                </div>
              </div>
            </HomeHeroRight>

            <HomeHeroLeft>
              <TextHeader>
                <br />
                <div>
                  {" "}
                  <span className="text-outline  ">“</span>
                  <span className="text-outline purple ">
                    Developer friendly
                  </span>
                  <span className="">code security</span>
                </div>
                <div className="">
                  <span className="">solution</span>,
                  <span className="text-outline  d">AI-Powered-</span>
                  <span className="text-outline  purple"> SAST </span>{" "}
                  <span className="text-outline  ">”</span>
                </div>
              </TextHeader>
              <SupplText className="textrig" style={{ marginBottom: "24px" }}>
                Secure your code as it’s written in real time using our <br />
                AI-Powered SAST tailored for you!
              </SupplText>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Button
                  bg="var(--sixthPurple)"
                  color="black"
                  hoverBg="var(--sixthPurple)"
                  hoverColor="var(--sixthLightPurple)"
                  buttonWidth=""
                  onClick={(e) => registerUser()}
                >
                  Start free
                </Button>
                <Button
                  style={{ marginLeft: "16px" }}
                  bg=""
                  hoverborder="3px solid var(--sixthPurple) "
                  color="var(--sixthPurple)"
                  hoverColor="var(--sixthLightPurple)"
                  hoverBg="var(--)"
                  onClick={(e) => handleClickScroll()}
                >
                  view demo
                  <MdArrowOutward size={24} />
                </Button>
              </div>
            </HomeHeroLeft>
            <HomeHeroRightBars margin="7%" />
            <HomeHeroRightBars bheight="60%" />
          </HomeHeroDivContent>
          <HomeHerorRound>
            <BsFillLightningChargeFill />

            <RotatingSpan>G</RotatingSpan>
            <RotatingSpan>E</RotatingSpan>
            <RotatingSpan>T</RotatingSpan>
            <RotatingSpan>T</RotatingSpan>
            <RotatingSpan>I</RotatingSpan>
            <RotatingSpan>N</RotatingSpan>
            <RotatingSpan>G</RotatingSpan>
            <RotatingSpan>.</RotatingSpan>
            <RotatingSpan>S</RotatingSpan>
            <RotatingSpan>T</RotatingSpan>
            <RotatingSpan>A</RotatingSpan>
            <RotatingSpan>R</RotatingSpan>
            <RotatingSpan>T</RotatingSpan>
            <RotatingSpan>E</RotatingSpan>
            <RotatingSpan>D</RotatingSpan>
            <RotatingSpan>.</RotatingSpan>
          </HomeHerorRound>
        </HomeHeroMainDiv>
      </HeroHeaderWrap>
      <VsDemoMain id="vs-code-demo">
        <h1> Here’s an interactive demo </h1>
        <VsDemoContent>
          <VsTop>
            <VsFileTop>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  width={"20px"}
                  src="/images/logos_python.png"
                  alt="python"
                ></img>
                <h4 style={{ margin: "8px" }}>email_sender.py</h4>
                <div>
                  <IoMdClose size={12} />
                </div>
              </div>
            </VsFileTop>
          </VsTop>
          <hr color="#3C4154" />
          <VsCodePageComp lines={errorcode} fixes={fix} codeFix={fixedcode} />
        </VsDemoContent>
      </VsDemoMain>
      <Services />
      <Tools />
      

      <Fade duration={2000}>
        <TrustedComponent/>  
      </Fade>
    </>
  );
};

export default HomeHero;


const errorcode = [
  {
    line: "1",
    isWrong: false,
    fixed: false,
    code: [
      {
        color: "#445197",
        text: "def",
        marginLeft: "0px",
        marginRight: "0px",
      },
      {
        color: "#506695",
        text: "send_email_to_all_user  ",
        marginLeft: "0.6rem",
        marginRight: "1rem",
      },
      {
        color: "#CDBF66",
        text: "( )  ",
        marginLeft: " 0.1rem",
        marginRight: "0px",
      },

      {
        color: "#506695",
        text: ":",
        marginLeft: " 0",
        marginRight: "0",
      },
    ],
  },
  {
    line: "2",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "user ",
        marginLeft: "2rem",
        marginRight: "4.5rem",
      },
      {
        color: "#445197",
        text: "=  ",
        marginLeft: "0.2rem",
        marginRight: "1rem",
      },
      {
        color: "#C1CBE6",
        text: "user_ref ",
        marginLeft: " 0.2rem",
        marginRight: "0",
      },
      {
        color: "#506695",
        text: ".get",
        marginLeft: " 0",
        marginRight: "0",
      },
      {
        color: "#CDBF66",
        text: "(   )  ",
        marginLeft: " 0.1rem",
        marginRight: "0",
      },
    ],
  },
  {
    line: "3",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "waitlist ",
        marginLeft: " 2rem",
        marginRight: "0.2rem",
      },
      {
        color: "#445197",
        text: "=  ",
        marginLeft: " 0.2rem",
        marginRight: "1rem",
      },
      {
        color: "#C1CBE6",
        text: "waitlist_users ",
        marginLeft: "0.1rem",
        marginRight: "0",
      },
      {
        color: "#506695",
        text: ".get",
        marginLeft: "0",
        marginRight: "0",
      },
      {
        color: "#CDBF66",
        text: "(   )  ",
        marginLeft: "0.1rem",
        marginRight: "0.1rem",
      },
    ],
  },
  {
    line: "4",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "emails ",
        marginLeft: "2rem",
        marginRight: "4.5rem",
      },
      {
        color: "#445197",
        text: "=  ",
        marginLeft: "0.2rem",
        marginRight: "1rem",
      },

      {
        color: "#CDBF66",
        text: "[ ]  ",
        marginLeft: "0.2rem",
        marginRight: "0.1rem",
      },
    ],
  },
  {
    line: "5",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#47868D",
        text: "for ",
        marginLeft: "2rem",
        marginRight: "0.2rem",
      },
      {
        color: "#C1CBE6",
        text: "user ",
        marginLeft: "0.5rem",
        marginRight: "1rem",
      },
      {
        color: "#47868D",
        text: "in ",
        marginLeft: "0.5rem",
        marginRight: "0px",
      },
      {
        color: "#C1CBE6",
        text: "users ",
        marginLeft: "0.5rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: ":",
        marginLeft: "0.2rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "6",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "new_user",
        marginLeft: "4rem",
        marginRight: "0.4rem",
      },
      {
        color: "#445197",
        text: "=",
        marginLeft: "0.2rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "users ",
        marginLeft: "0.2rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: "[ ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "user ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: " ] ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: "[ ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#506695",
        text: '"',
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "email ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#506695",
        text: ' "',
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },

      {
        color: "#CDBF66",
        text: "] ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "7",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "email ",
        marginLeft: "4rem",
        marginRight: "0.4rem",
      },

      {
        color: "#506695",
        text: ".append",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: "(",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#506695",
        text: "new_user",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: " )  ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "8",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#47868D",
        text: "for ",
        marginLeft: "2rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "user ",
        marginLeft: "0.4rem",
        marginRight: "0.4rem",
      },
      {
        color: "#47868D",
        text: "in ",
        marginLeft: "0.4rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "waitlist ",
        marginLeft: "0.4rem",
        marginRight: "0.4rem",
      },
      {
        color: "#506695",
        text: ":",
        marginLeft: "0.2rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "9",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "new_user ",
        marginLeft: "4rem",
        marginRight: "0.4rem",
      },
      {
        color: "#445197",
        text: "=",
        marginLeft: "0.4rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "waitlist ",
        marginLeft: "0.4rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: "[ ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "user ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: "] ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: "[ ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#506695",
        text: '"',
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "email ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#506695",
        text: ' "',
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },

      {
        color: "#CDBF66",
        text: "] ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "10",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "email ",
        marginLeft: "4rem",
        marginRight: "0.4rem",
      },

      {
        color: "#506695",
        text: ".append",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: "(",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#506695",
        text: "new_user",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: " )  ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "11",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "print ",
        marginLeft: "2rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: "(",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#506695",
        text: "emails",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: " )  ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "12",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#47868D",
        text: "return ",
        marginLeft: "2rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "emails ",
        marginLeft: "0.4rem",
        marginRight: "0.4rem",
      },
    ],
  },
];

const fixedcode = [
  {
    line: "1",
    isWrong: false,
    fixed: false,
    code: [
      {
        color: "#445197",
        text: "def",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: "send_email_to_all_user  ",
        marginLeft: "0.6rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: "( )  ",
        marginLeft: "0.4rem",
        marginRight: "0.4rem",
      },

      {
        color: "#506695",
        text: ":",
        marginLeft: "0.4rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "2",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#47868D",
        text: "try: ",
        marginLeft: "2rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "3",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "user ",
        marginLeft: "4rem",
        marginRight: "0rem",
      },
      {
        color: "#445197",
        text: "=  ",
        marginLeft: "0.2rem",
        marginRight: "0rem",
      },
      {
        color: "#C1CBE6",
        text: "user_ref ",
        marginLeft: "0.2rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: ".get",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: "(   )  ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
    ],
  },
  {
    line: "4",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "waitlist ",
        marginLeft: "4rem",
        marginRight: "0rem",
      },
      {
        color: "#445197",
        text: "=  ",
        marginLeft: "0.2rem",
        marginRight: "0em",
      },
      {
        color: "#C1CBE6",
        text: "waitlist_users ",
        marginLeft: "0.2rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: ".get",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: "(   )  ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
    ],
  },
  {
    line: "5",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "emails ",
        marginLeft: "4rem",
        marginRight: "0rem",
      },
      {
        color: "#445197",
        text: "=  ",
        marginLeft: "0.2rem",
        marginRight: "0rem",
      },

      {
        color: "#CDBF66",
        text: "[ ]  ",
        marginLeft: "0.2rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "6",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#47868D",
        text: "for ",
        marginLeft: "4rem",
        marginRight: "0rem",
      },
      {
        color: "#C1CBE6",
        text: "user ",
        marginLeft: "0.4rem",
        marginRight: "0rem",
      },
      {
        color: "#47868D",
        text: "in ",
        marginLeft: "0.4rem",
        marginRight: "0rem",
      },
      {
        color: "#C1CBE6",
        text: "users ",
        marginLeft: "0.4rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: ":",
        marginLeft: "0.2rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "7",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "new_user ",
        marginLeft: "8rem",
        marginRight: "0rem",
      },
      {
        color: "#445197",
        text: "=",
        marginLeft: "0.2rem",
        marginRight: "0rem",
      },
      {
        color: "#C1CBE6",
        text: "users ",
        marginLeft: "0.2rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: "[ ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#C1CBE6",
        text: "user ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: "] ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: "[ ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: '"',
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#C1CBE6",
        text: "email ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: ' "',
        marginLeft: "0rem",
        marginRight: "0rem",
      },

      {
        color: "#CDBF66",
        text: "] ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
    ],
  },
  {
    line: "8",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "email ",
        marginLeft: "8rem",
        marginRight: "0rem",
      },

      {
        color: "#506695",
        text: ".append",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: "(",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: "new_user",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: " )  ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
    ],
  },
  {
    line: "9",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#47868D",
        text: "for ",
        marginLeft: "4rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "user ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#47868D",
        text: "in ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "waitlist ",
        marginLeft: "0rem",
        marginRight: "0.2rem",
      },
      {
        color: "#506695",
        text: ":",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "10",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "new_user ",
        marginLeft: "8rem",
        marginRight: "0.4rem",
      },
      {
        color: "#445197",
        text: "=",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "waitlist ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: "[ ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#C1CBE6",
        text: "user ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: "] ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: "[ ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: '"',
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#C1CBE6",
        text: "email ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: ' "',
        marginLeft: "0rem",
        marginRight: "0rem",
      },

      {
        color: "#CDBF66",
        text: "] ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
    ],
  },
  {
    line: "11",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#C1CBE6",
        text: "emails ",
        marginLeft: "8rem",
        marginRight: "0rem",
      },

      {
        color: "#506695",
        text: ".append",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: "(",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: "new_user",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#CDBF66",
        text: " )  ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
    ],
  },
  {
    line: "12",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#506695",
        text: "# print (emails , len(emails)) removed print",
        marginLeft: "4rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "13",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#47868D",
        text: "return ",
        marginLeft: "4rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "emails ",
        marginLeft: "0.4rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "14",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#47868D",
        text: "except ",
        marginLeft: "2rem",
        marginRight: "0.4rem",
      },

      {
        color: "#506695",
        text: "Exception  ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#47868D",
        text: "as  ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#C1CBE6",
        text: "e ",
        marginLeft: "0rem",
        marginRight: "0.2rem",
      },
      {
        color: "#47868D",
        text: ":",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
    ],
  },
  {
    line: "15",
    isWrong: true,
    fixed: false,
    code: [
      {
        color: "#47868D",
        text: "print ",
        marginLeft: "4rem",
        marginRight: "0rem",
      },

      {
        color: "#CDBF66",
        text: "(  ",
        marginLeft: "0rem",
        marginRight: "0.2rem",
      },
      {
        color: "#506695",
        text: "f  ",
        marginLeft: "0rem",
        marginRight: "0rem",
      },
      {
        color: "#506695",
        text: '"An error occurred: ',
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },

      {
        color: "#CDBF66",
        text: "{ ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#506695",
        text: "e ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: "} ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#506695",
        text: ' "',
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
      {
        color: "#CDBF66",
        text: ") ",
        marginLeft: "0rem",
        marginRight: "0.4rem",
      },
    ],
  },
];

const fix = [
  "VULNER: OWASP A3:2017-Sensitive Data Exposure - Email addresses are being printed which could lead to data leakage.",

  "VULNER: CWE-400: Uncontrolled Resource Consumption - The code could potentially fetch large amounts of data from the database, leading to high memory consumption.",
  "VULNER: OWASP A6:2017-Security Misconfiguration - No error handling  is present ,which could lead to unhandled exception and potential system instability. ",
  "VULNER: CWE-89: Improper Neutralization of special Elements  used in an Sql command ('SQL injection') - If the user input is not properly sanitized, it could lead t SQL injection.",
  "VULNER: OWASP A5:2017-Broken Access Control - The function seems to access all users' data without any access control or authentication . ",
  "FIX: Remove the print statement that exposes email addresses .",
  "FIX:  Implement pagination or limit the number of users fetched ata a time.",
  "FIX:  Add error handling to catch any potential exception ",
  "FIX: Use Parameterized queries or prepared statements to prevent SQL injection. ",
  "FIX: Implement proper access control and authentication to ensure nly authorized users can access the data.   ",
];
