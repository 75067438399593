import {
  FeedbackField,
  FeedbackFieldInput,
  FeedbackMainDiv,
  FeedbackSection,
} from "../../styles/feedbackStyles/feedback";
import AppHeader from "../reusable/header";

const FeedbackComponent = () => {
  return (
    <>
      <FeedbackMainDiv>
        <AppHeader
          hideContact={true}
          logo={"/images/sixthWhiteIcon.png"}
          top="0.5rem"
          width="10rem"
          mobileWidth="8rem"
        />
        <FeedbackSection>
          <h1> Feedback form</h1>

          <FeedbackField>
            <p>Name</p>
            <FeedbackFieldInput placeholder={"john doe"} />
          </FeedbackField>

          <FeedbackField>
            <p>Email</p>
            <FeedbackFieldInput placeholder={"johndoe@gmail.com"} />
          </FeedbackField>
          <FeedbackField>
            <p>Comment</p>
            <FeedbackFieldInput height="15rem" placeholder="write your review of sixth" />
          </FeedbackField>
        </FeedbackSection>
      </FeedbackMainDiv>
    </>
  );
};
export default FeedbackComponent;
