import React, {useEffect } from 'react';
import HomeHero from '../components/homeComps/homeHero';
import Services from '../components/homeComps/services';
import Tools from '../components/homeComps/tools';
import Footer from '../components/reusable/footer';
import DemoSection from '../components/homeComps/demoSection';

const Home = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return(
        <>
            <div className="main-widget">
                <main>
                <HomeHero />
              
                {/* <DownloadSection /> */}
                <DemoSection/>
                
               
                    
                </main>

                <Footer />
            </div>
        </>
    )
} 

export default Home;