import {
  TrustedSection,MarqueeContainer,
  MarqueeContent,
  MarqueeItem,
  TrustedSectionImg,
} from "../../styles/homeComp/trustedStyles";

const TrustedComponent =()=>{
  
    return (
      <>
        <TrustedSection>
          <h1> Trusted by Developer from</h1>
          <MarqueeContainer>
            
            <MarqueeContent>
              {trustedCard.map((item, index) => (
                <MarqueeItem>
                  <TrustedSectionImg
                    src={item.src}
                    alt="icon"
                    imgHeight={item.imgHeight}
                    imgWidth={item.imgWidth}
                    mobileHeight={item.mobileHeight}
                  />
                </MarqueeItem>
              ))}
              
            </MarqueeContent>
          </MarqueeContainer>
        </TrustedSection>
      </>
    );
}
export default TrustedComponent


const trustedCard = [
  {
    src: "/images/microsoft.png",
    imgHeight: "45px",
    imgWidth: "200px",
    mobileHeight: "45px",
    
  },
  {
    src: "/images/netflix2.png",
    imgHeight: "40px",
    imgWidth: "165px",
    mobileHeight: "40px",
  },
  {
    src: "/images/hulu2.png",
    imgHeight: "45px",
    imgWidth: "130px",
    mobileHeight: "40px",
  },

  {
    src: "/images/tesla2.png",
    imgHeight: "40px",
    imgWidth: "170px",
    mobileHeight: "40px",
  },
  {
    src: "/images/google.png",
    imgHeight: "45px",
    imgWidth: "200px",
    mobileHeight: "45px",
  },
  {
    src: "/images/spacex.png",
    imgHeight: "45px",
    imgWidth: "200px",
    mobileHeight: "",
  },
  {
    src: "/images/microsoft.png",
    imgHeight: "45px",
    imgWidth: "200px",
    mobileHeight: "",
  },
  {
    src: "/images/netflix2.png",
    imgHeight: "40px",
    imgWidth: "165px",
    mobileHeight: "40px",
  },
];