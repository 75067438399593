import styled from "styled-components";

interface ImgDivProp {
  mainWidth?: string;
  padding?: string;
}
export const ImgHero = styled.div<ImgDivProp>`
  margin: 0px;
  overflow: hidden;
  background: red;
  height: 450px;
  background: var(--sixth-gradient5);
  backdroup-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  overflow: hidden;
  width: ${(p) => p.mainWidth || "50%"};
  border-radius: 3px 0 0 7px;
  > img {
    transform-origin: 10% 100%;
    transform: translatex(-220px);
  }
  @media (max-width: 1100px) {
    width: 60vw;
  }
  @media (max-width: 450px) {
    width: 100%;

    > img {
      transform-origin: 100% 100%;
      transform: translatex(-0px);
    }
  }
`;
export const VsCodeImg = styled.img`
  width: 150%;
  height: 140%;
  object-fit: cover;

  @media (max-width: 1300px) {
    width: 100%;
  }
  @media (max-width: 450px) {
  }
`;

interface HeroMainProp {
  bg?: string;
  height?: string;
}

export const HomeHeroDiv = styled.section<HeroMainProp>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 2rem 0;
  background: ${(p) => p.bg || "transparent"};
  padding: 2rem 4%;
  height: ${(p) => p.height || "auto"};

  @media (max-width: 1440px) {
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    width: auto;
    margin: 0px;
  }
`;

export const VsDemoMain = styled.div`
  min-height: 90vh;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: black;
  postion: relative;

  > h1 {
    font-size: 38px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
  }

  @media (max-width: 1280px) {
    margin: 4% 1rem;
  }

  @media (max-width: 1280px) {
    display: none;
  }
`;

export const VsDemoContent = styled.div`
  width: 70%;

  min-height: 400px;
  padding: 1rem 1rem;
  border-radius: 1.5rem;
  border: 1px solid transparent;
  box-shadow: 1px 2px 2px 1px #dab9ff80;
  backdrop-filter: blur(19px);
  background: linear-gradient(black, #0d1117) padding-box,
    linear-gradient(
        103.55deg,
        #7152dc 16.12%,
        #2886a9 41.87%,
        rgba(63, 122, 144, 0.53) 84.45%
      )
      border-box;

  @media (max-width: 1550px) {
    width: 75%;
    height: 60vh;
    padding: 1rem 0.5rem;
  }
`;

export const VsTop = styled.div`
  display: flex;
`;

export const VsFileTop = styled.div`
  cursor: pointer;
  h4 {
    color: #47868d;
  }
  > div {
    display: flex;
    color: #c1cbe6;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    margin-left: 48px;
    margin-bottom: 0px;
  }
`;

export const HomeHeroMainDiv = styled.div`
  min-height: 59vh;
  background: black;
  border: 0px 1px 0px 1px solid white;
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px;
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px;
  background: linear-gradient(180deg, #000000 0%, #10062b 100%);
`;

export const HomeHeroMainHeadText = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  > h1 {
    font-size: 7.6em;
    font-weight: 800;
    text-transform: uppercase;
    padding: 50px 0 0;
    letter-spacing: -11px;
    margin: 0 auto;
    text-align: center;
  }
  .span {
    background: white;
    color: black;
    display: flex;
    justify-content: space-between;
    border-radius: 6px 50px 50px 7px;
    align-items: center;
    padding: 0 10px 0 40px;

    h1 {
      font-size: 2.6em;
      font-weight: 300;
      text-transform: uppercase;
      padding: 10px 0;
      margin: 0 auto;
      text-align: left;
      color: black;
    }
    > span {
      > p {
        font-size: 1.5em;
        padding: 0;
        margin: 0;
        transform: translateY(-8px);
        transition: all 0.8s;
        line-height: 1em;
      }
      display: flex;
      height: auto;
      justify-content: space-between;
      gap: 1rem;
      align-items: baseline;
      text-transform: capitalize;
      padding: 10px 0;
      line-height: 10px;
      cursor: pointer;
      font-weight: 600;
      z-index: 3;
      > div {
        position: relative;
      }

      :hover {
        > p {
          transform: translateY(-18px);
        }
        > div {
          color: var(--sixthLightPurple);
          ::after {
            width: 100px;
            height: 100px;
            transition: all 1s;
          }
          ::before {
            width: 0px;
            height: 0px;
            transition: all 0.4s;
          }
        }
      }
    }
  }
  @media (max-width: 1380px) {
    margin-top: 3rem;
    margin-bottom: 1rem;
    > h1 {
      font-size: 7em;
    }
  }
  @media (max-width: 1214px) {
    > h1 {
      font-size: 6.5em;
    }
  }
  @media (max-width: 1125px) {
    > h1 {
      font-size: 6em;
    }
  }
  @media (max-width: 1024px) {
    > h1 {
      font-size: 5em;
      letter-spacing: -6px;
    }
    .span {
      h1 {
        font-size: 1.8em;
        font-weight: 300;
        padding: 10px 0;
      }
    }
  }
  @media (max-width: 950px) {
    > h1 {
      font-size: 4.5em;
      letter-spacing: -6px;
    }
  }
  @media (max-width: 780px) {
    > h1 {
      font-size: 3.5em;
      letter-spacing: -2px;
    }
    .span {
      h1 {
        font-size: 1.3em;
      }
    }
  }
  @media (max-width: 680px) {
    > h1 {
      font-size: 3em;
    }
    .span {
      h1 {
        font-size: 1em;
      }
    }
  }
  @media (max-width: 580px) {
    > h1 {
      font-size: 2.5em;
    }
    .span {
      h1 {
        font-size: 1em;
        font-weight: 400;

        padding: 10px 0;
      }
      padding: 0 1rem;
      > span {
        > div {
          position: relative;
        }
        align-items: center;

        > p {
          font-size: 1em;
          font-weight: 800;
          transform: translateY(0px);
        }

        :hover {
          > p {
            transform: translateY(-18px);
          }
          > div {
            color: var(--sixthLightPurple);
            ::after {
              width: 50px;
              height: 50px;
              transition: all 1s;
            }
            ::before {
              width: 0px;
              height: 0px;
              transition: all 0.4s;
            }
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    > h1 {
      font-size: 1.7em;
      font-weight: 600;
      padding: 0px 0 0;
      letter-spacing: -1px;
      margin: 0 auto;
      text-align: center;
    }

    .span {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 6px 50px 50px 7px;

      padding: 0 10px 0 0px;

      h1 {
        font-size: 1em;
        padding: 10px 0;
      }
      > span {
        display: none;
        > div {
          position: relative;
        }

        :hover {
          > p {
            transform: translateY(-18px);
          }
          > div {
            color: var(--sixthLightPurple);
            ::after {
              width: 50px;
              height: 50px;
              transition: all 1s;
            }
            ::before {
              width: 0px;
              height: 0px;
              transition: all 0.4s;
            }
          }
        }
      }
    }
  }
`;

export const HomeHeroMainLinkButton = styled.div`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  z-index: 1;
  color: white;

  > svg {
    width: 500px;
    height: 300px;

    padding: 0;
    margin: 0;
  }
  ::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 0px;

    height: 0px;
    border-radius: 100%;
    transition: all 0.4s;
    background-position: center;
    background: var(--sixthPurple);

    z-index: -1;
  }
  ::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    background: black;
    height: 100px;
    border-radius: 100%;
    transition: all 1s;
    background-position: center;
    z-index: -1;
  }
  @media (max-width: 450px) {
    display: none;
  }
`;

export const HomeHeroDivContent = styled.div`
  min-height: 30vh;

  border: 0px 1px 0px 1px solid white;
  display: flex;
  padding: 0 100px;
  margin-top: 100px;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 50px;
    margin: 0;
    padding: 0;
    width: 100%;
    flex-direction: column-reverse;
    > div {
      width: 90%;
      padding: 0 1rem;
      text-align: center;
      margin-bottom: 2rem;

      > h2 {
        line-height: 45px;
        font-size: 35px;
      }
    }
  }
`;

export const HomeHeroLeft = styled.div`
  width: 45%;
  text-align: right;
  margin-right: 20px;
  .textleft {
    text-align: left;
  }
  .textright {
    text-align: right;
  }
  .text-outline {
    font-size: 1.2em;
    text-transform: upperase;
    color: white;
    font-weight: 800;
    algn-items: end;
    font-size: 48px;
    line-height: 1.1em;
    text-shadow: -2px -1px 0 var(--sixthPurple), 1px -1px 0 var(--sixthPurple),
      -1px 1px 0 var(--sixthPurple), 1px 1px 0 var(--sixthPrple);
  }
  .d {
    color: white;

    text-shadow: -3px -1px 0 var(--sixthPurple), 1px -1px 0 var(--sixthPurple),
      -1px 1px 0 var(--sixthPurple), 1px 1px 0 var(--sixthPurple);
  }
  .purple {
    color: var(--sixthPurple);
    text-shadow: -1px -1px 0 var(--sixthPurple), 1px -1px 0 var(--sixthPurple),
      -1px 1px 0 var(--sixthPurple), 1px 1px 0 var(--sixthPurple);
  }
  > h1 {
    font-weight: 100;
    font-size: 30px !important;
    line-height: 44px;
    color: white;
    color: var(--sixthPurple);
    font-size: 1.5em;
    padding: 0;
    margin: 0;
    transition: all 0.8s;
    line-height: 1em;
  }
  > p {
    font-weight: 400;
    color: var(--sixthPurple);
    font-size: 1.2em;
    padding: 0;
    margin: 0;
    transition: all 0.8s;
    line-height: 2em;
  }

  button {
    border-radius: 40px;
    font-weight: 800;
    font-size: 18px !important;
    :hover {
      > svg {
        color: white;
      }
    }

    > svg {
      color: var(--sixthPurple);
    }
  }
`;

export const HomeHerorRound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  postion: absolute;
  left: 50%;
  bottom: -30px;
  z-index: 10;

  width: 100px;
  height: 100px;
  position: relative;
  text-align: center;
  background: white;
  color: black;
  border-radius: 50%;
  --noOfItems: 16;
  > svg {
    width: 30px;
    height: 30px;
  }
`;
export const RotatingSpan = styled.span`
  --units: 1;
  --rotationUnit: calc((1turn / var(--noOfItems)) * var(--units, 1));
  position: absolute;
  height: calc(100% + 3px);
  width: calc(100% + 3px);
  font-weight: 800;
  font-size: 13px;
  top: -2px;
  left: -2px;

  transform: rotate(var(--rotationUnit));
  transform-origin: center;
  transition: ;

  &:nth-child(2) {
    --units: 2;
  }
  &:nth-child(3) {
    --units: 3;
  }
  &:nth-child(4) {
    --units: 4;
  }
  &:nth-child(5) {
    --units: 5;
  }
  &:nth-child(6) {
    --units: 6;
  }
  &:nth-child(7) {
    --units: 7;
  }
  &:nth-child(8) {
    --units: 8;
  }
  &:nth-child(9) {
    --units: 9;
  }
  &:nth-child(10) {
    --units: 10;
  }
  &:nth-child(11) {
    --units: 11;
  }
  &:nth-child(12) {
    --units: 12;
  }
  &:nth-child(13) {
    --units: 13;
  }
  &:nth-child(14) {
    --units: 14;
  }
  &:nth-child(15) {
    --units: 15;
  }
  &:nth-child(16) {
    --units: 16;
  }
`;

export const HomeHeroRight = styled.div`
  min-height: 480px;
  min-width: 200px;
  width: 50%;
  padding-left: 5%;
  border-radius: 30px;
  z-index: 1;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid transparent;
  color: white;
  background: linear-gradient(black, #0d1117) padding-box,
    linear-gradient(
        103.55deg,
        #7152dc 16.12%,
        #2886a9 41.87%,
        rgba(63, 122, 144, 0.53) 84.45%
      )
      border-box;

  .auto {
    color: var(--sixthPurple);
    font-weight: 800;
  }
  .code {
    height: 30px;
    border-radius: 100%;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-bg);

    > svg {
      color: var(--sixthLightPurple);
      height: 20px;
      width: 20px;
    }
  }
  .left {
    display: flex;
    gap: 0.4rem;
    align-items: end;
    font-weight: 600;
    font-size: 20px;
  }
  .bold {
    font-weight: 600;
    font-size: 32px;
    line-height: 10px;
    color: white;
  }
  .end-line {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 20px 0;
    color: white;
    font-size: 16px;
    width: 90%;
  }
  .complete {
    color: var(--sixthLightPurple);
    opacity: 0.5;
  }
  .fade {
    opacity: 0.5;
  }
  @media (max-width: 450px) {
    .end-line {
      width: 100%;
    }
    .code {
      height: 40px;
      width: 40px;

      > svg {
        height: 40px;
        width: 40px;
      }
    }
  }
`;

interface barprop {
  margin?: string;
  bheight?: string;
}

export const HomeHeroRightBars = styled.div<barprop>`
  width: 8px;
  padding: 0;
  z-index: 1;
  background: var(--sixthPurple);
  position: relative;
  border-radius: 3px;
  margin: ${(p) => p.margin || "10%"} 10px 2% 0;

  color: var(--sixthLightPurple);
  ::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -30px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    transition: all 0.4s;
    background-position: center;
    z-index: -1;
    background: var(--sixthLightPurple);
    opacity: 1;
  }
  ::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: White;
    opacity: 1;
    height: ${(p) => p.bheight || "80%"};
    border-radius: 3px;
    transition: all 1s;
    background-position: center;
    z-index: -1;
  }
  @media (max-width: 460px) {
    display: none;
  }
`;
