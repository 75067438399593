import { useState } from "react";
import {
  FaqContent,
  FaqDropDown,
  FaqFieldHead,
} from "../../styles/faqStyles/faqCompStyles";

const Faqs = ({ title = "", answer = "" }) => {
  const [openFull, setOpenFull] = useState(false);
  return (
    <>
    
      <FaqFieldHead>
        <p>{title}</p>

        <FaqDropDown
          src="/images/dropdown.png"
          alt="dropdown"
          title="dropdown"
          open={openFull}
          onClick={() => {
            setOpenFull(!openFull);
          }}
        />
      </FaqFieldHead>
      <FaqContent>{openFull ? <p>{answer}</p> : <></>}
      </FaqContent>

      <hr color="#F1E4FF" />
    </>
  );
};

export default Faqs;
