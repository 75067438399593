import FeedbackComponent from "../components/feedbackComp/feedbackComp"

const Feedback = () =>{
    return(
        <>
        <FeedbackComponent/>
        </>
    )

} 
export default Feedback 