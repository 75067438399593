import styled, { keyframes } from "styled-components";

export const TrustedSection = styled.div`
  margin: 12rem 0 15rem 0;
  align-items: center;
  text-align: center;

  > h1 {
    font-size: 2.8em;
    margin: 0 0 0rem 0;
    line-height: 1em;
    font-weight: 800;
    text-transform: uppercase;
  }
  @media (max-width: 550px) {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
`;

interface TrustedSectionImgProp {
  imgWidth?: string;
  imgHeight?: string;
  mobileHeight?: string;
}
export const TrustedSectionImg = styled.img<TrustedSectionImgProp>`
  width: ${(p) => p.imgWidth || "30rem"};
  height: ${(p) => p.imgHeight || "60px"};

  @media (max-width: 550px) {
    width: 150px;
    height: ${(p) => p.mobileHeight || "60px"};
  }
`;
export const MarqueeContainer = styled.div`
  --marquee-width: 100vw;
  --marquee-height: 10vh;
  --marquee-elements-displayed: 7;
  --marquee-element-width: calc(
    var(--marquee-width) / var(--marquee-elements-displayed)
  );
  --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
  width: var(--marquee-width);
  min-height: var(--marquee-height);
  background-color: black;
  color: black;
  overflow: hidden;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 5rem;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    width: 10rem;
    height: 100%;
    content: "";
    z-index: 1;
  }

  &:before {
    left: 0;
    background: linear-gradient(to right, black 0%, transparent 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(to left, black 0%, transparent 100%);
  }
  @media (max-width: 550px) {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
`;

const scrolling = keyframes`
  to { left: -200px }
`;

export const MarqueeContent = styled.div`
  margin-inline: auto;
  position: relative;
  height: 100px;
  width: 100vw;

  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
  background: #010409;
  z-index: 2;

  &:before,
  &:after {
    content: "hdhdh";
    position: absolute;
    top: 0;
    width: 10rem;
    height: 100%;
    content: "";
    z-index: 1;
  }

  &:before {
    left: 0;
    background: linear-gradient(to right, #111 0%, transparent 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(to left, #111 0%, transparent 100%);
  }

  :hover {
    > div {
      animation-play-state: paused;
    }
  }
  @media (max-width: 550px) {
  }
`;

export const MarqueeItem = styled.div`
  --units: 1;
  width: 200px;
  height: 70px;
  background-color: transparent;

  display: flex;
  align-items: center;
  top: 15px;
  border-radius: 6px;
  position: absolute;
  left: max(calc(200px * 8), 100%);
  animation-name: ${scrolling};
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: calc(30s / 8 * (8 - var(--units)) * -1);
  &:nth-child(2) {
    --units: 2;
  }
  &:nth-child(3) {
    --units: 3;
  }
  &:nth-child(4) {
    --units: 4;
  }
  &:nth-child(5) {
    --units: 5;
  }

  &:nth-child(6) {
    --units: 6;
  }

  &:nth-child(7) {
    --units: 7;
  }

  &:nth-child(8) {
    --units: 8;
  }
  > svg {
    width: 40px;
    height: 40px;
  }
`;
