import { FaqFooter, FaqHeader, FaqMainDiv, FaqMainSection, FaqQuestionDiv, FaqSection } from "../../styles/faqStyles/faqCompStyles";
import { Button } from "../../styles/reusable";
import * as Icon from "react-feather";
import Footer from "../reusable/footer";
import AppHeader from "../reusable/header";
import { useNavigate } from "react-router";

const FaqComponent = () => {
    const opemMail = () =>
    {
        console.log("mail")


    }
    const navigate = useNavigate()
    const OpenFeedback =() =>
    {
        navigate("/feedback")
    }
  return (
    <>
      <FaqMainDiv>
        <AppHeader
          hideContact={true}
          logo={"/images/sixthWhiteIcon.png"}
          top="0.5rem"
          width="10rem"
          mobileWidth="8rem"
        />
        <FaqHeader>
            <> <h1>Frequently Asked Questions </h1></>
            <div>
            <Button
            bg="var(--sixthPurple)"
            onClick={() =>{
                OpenFeedback()
            }}
            
            >Feedback</Button>

            </div>
           
         
        </FaqHeader>
        <FaqMainSection>
            {
                data.map((item, index) =>
                (
                    <FaqSection>
                        <div style={{display:"flex",}}>
                        
                        </div>
                        <FaqQuestionDiv>
                        <Icon.HelpCircle size={30} color="var(--sixthPurple)" ></Icon.HelpCircle>
                        <h3>
                            
                            {item.question}
                        </h3>

                        </FaqQuestionDiv>
                        
                        {
                            item.open ?<>
                            <p>
                            {item.answer}
                            </p>
                            </>:
                            <>
                            </>
                        }
                    </FaqSection>

                )

                )
            }

        </FaqMainSection>
        <FaqFooter>
        <h3> Have other questions ?</h3>
        <p><span onClick={()=>{ opemMail()}}>Contact us</span>  here so can help you.</p>

        </FaqFooter>
        


        <Footer />
      </FaqMainDiv>
    </>
  );
};
export default FaqComponent;


const data = [
    {
        open:true,
        question:"Lorem ipsum dolor sit amet, consectetur adipiscing ?",
        answer:"Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.. "
    },
    {
        open:true,
        question:"What is Sixth",
        answer:"Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.. "
    },
    {
        open:true,
        question:"What is Sixth",
        answer:"Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.. "
    },
]
