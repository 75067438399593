import styled from "styled-components";

export const PriceMainDiv = styled.div`
  position: relative;

  ::before {
    content: "";
    position: absolute;

    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: var(--sixth-gradient4);

    border-radius: 10px;
    border-image: linear-gradient(180deg, #c352d3 0%, #a767db 100%) 1;
  }
`;
export const PriceSection = styled.div`
  text-align: center;
`;

export const PriceItems = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 90px;
  @media (max-width: 728px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const PricingCard = styled.div`
  background: linear-gradient(0deg, rgb(0 0 0), rgb(27 3 51));
  flex-grow: 1;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 25%);
  padding: 50px 10px 20px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 13px;
  width: 43%;
  max-width: 450px;
  min-width: 300px;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: linear-gradient(180deg, #c352d3 0%, #a767db 100%);
    border-radius: 10px;
    border-image: linear-gradient(180deg, #c352d3 0%, #a767db 100%) 1;
  }

  > div {
  }
  @media (max-width: 728px) {
    width: 95%;
    padding: 30px 2.5% 30px 2.5%;
  }
  
`;

interface PriceCardButtonProp {
  bg?: string;
  color?: string;
}

interface PriceCardSectionProp {
  color?: string;
  itemPos?: string;
  justifyDivContent?: String;
}
export const PriceCardSection = styled.div<PriceCardSectionProp>`
  width: 90%;
  gap: 5px;
  padding: 5px 70px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: ;

  > h1 {
    text-transform: capitalize;
  }
  h5 {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize;

    margin: 0px;
  }
  .duration {
    font-size: 16px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #757575;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  > div {
    align-items: center;
    justify-content: ${(p) => p.itemPos || "left"};
    gap: 5px;
    padding: 5px 0;
    fontsize: 15px;
    text-align: left;
    color: ${(p) => p.color || "white"};
    display: flex;
    text-transform: capitalize;
  }

  @media (max-width: 728px) {
    padding: 0px;
  }
`;

interface PriceCardIconProp {
  imgWidth?: string;
  imgHeight?: string;
  margin_left?: string;
}
export const PriceCardIcon = styled.img<PriceCardIconProp>`
  height: ${(p) => p.imgHeight || "24px"};
  width: ${(p) => p.imgWidth || "24px "};
`;

export const PriceCardButton = styled.div<PriceCardButtonProp>`
  height: 64px;
  width: 90%;
  border: 0.75px solid rgba(140, 48, 245, 1);
  text-align: center;
  justify-content: center;
  text-transform: capitalize;
  border-radius: 8px;
  display: flex;
  background: ${(P) => P.bg || "var(--sixth-gradient3)"};
  color: ${(P) => P.color || "var(--sixth-white)"};
  cursor: pointer;
  font-size: 1.5rem;
  align-items: center;
`;

export const PriceYearSection = styled.div<PriceCardSectionProp>`
  width: 90%;
  gap: 5px;
  padding: 0px 70px;
  font-size: 15px;
  display: block !important ;

  h5 {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center !important;
    text-transform: capitalize;

    margin: 0px;
  }
  .year {
    font-size: 16px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #757575;
    text-decoration: none;
  }
  .cancled {
    text-decoration: line-through;
    text-decoration-color: white;
    color: #757575;
    font-size: 27px;
    padding-right: 10px;
  }

  @media (max-width: 728px) {
    padding: 0px;
  }
`;

export const PriceDurationSection = styled.div`
  > div {
    display: flex;
    width: fit-content;
    gap: 10px !important;
    background: #9039f1;
    border-radius: 10px;
    padding: 7px 6px !important;

    box-shadow: 0 -20px 10px -20px rgba(0, 0, 0, 0.45) inset,
      -20px 0 10px -20px rgba(0, 0, 0, 0.45) inset;
  }
`;

interface PriceDurationSpanProp {
  active?: boolean;
}
export const PriceDurationSpan = styled.div<PriceDurationSpanProp>`
  font-size: 18px;
  font-weight: 900;
  padding: 7px;
  min-width: 70px;
  border-radius: 7px;
  marginl: 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s;
  color: ${(p) => (p.active ? "white" : "black")};
  background: ${(p) => (p.active ? "rgb(23 2 43)" : "transparent")};
  box-shadow: ${(p) =>
    p.active
      ? "rgb(255, 255, 255) 0px 1px 1px 0px, rgb(255, 255, 255) 0px 1px 1px 1px"
      : "none"};

 
`;

export const PriceInfoSpan = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 7px;
  min-width: 70px;
  border-radius: 7px;
  marginl: 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s;
  color: white;
  text-transform: uppercase;
  position: absolute;
  right: 10px;
  top: 6px;

  background: #9039f1;

  @media (max-width: 380px) {
    font-size: 16px;

  
  }
   
`;
